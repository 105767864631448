import React from 'react'
import styled from 'styled-components'
import { themes } from './styles/ColorStyles'

const BigWrap = styled.button`
    width: 56px;
    height: 56px
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${themes.light.neutral9};
    border-radius: 8px;
    border: none;
    margin-left: 24px;
    transition: 500ms;

    &:hover  {
        transform: scale(1.05);
        cursor: pointer;
    }

    &:active  {
        transform: scale(0.95);
        cursor: pointer;
        background-color: ${themes.light.neutral8};
    }

    @media (max-width:800px) {
        margin-top: 24px;
        width: 100%;
        margin-left: 0px;
        padding: 0px 24px 0px 24px;
    }
`

const Wrap = styled.div`
  display: flex;
`
const Icon = styled.img`
  margin: 0 auto;

  @media (max-width: 800px) {
    margin: 0px;
  }
`

const Text = styled.h5`
  display: none;

  @media (max-width: 800px) {
    display: flex;
    width: 100%;
    color: ${themes.light.neutral1};
    font-size: 18px;
    margin: 18px 0px 18px 0px;
  }
`

const BtnPrimary = (props) => (
  <BigWrap>
    <Wrap>
      <Text>{props.text}</Text>
      <Icon src={props.image} />
    </Wrap>
  </BigWrap>
)

export default BtnPrimary
