import React, { useState } from 'react'
import styled from 'styled-components'
import BtnPrimary from '../components/BtnPrimary'
import Layout from '../components/Layout'
import { navigate } from 'gatsby'
import { handleLogin, isLoggedIn } from '../utils/auth'
import { themes } from '../components/styles/ColorStyles'
import { Slide } from '../components/styles/Animation'
import { fontWeight, Title32 } from '../components/styles/TextStyles'

const Input = styled.input`
  height: 56px;
  padding: 0px 24px;
  background-color: ${themes.light.neutral1};
  margin: 0px 0px;
  color: ${themes.light.neutral9};
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  transition: 300ms;
  border: 1px solid ${themes.light.neutral4};

  &::placeholder {
    color: ${themes.light.neutral6};
  }

  &:hover {
    border: 1px solid ${themes.light.neutral5};
  }

  &:active {
    background-color: ${themes.light.neutral2};
    border-radius: 16px;
  }

  &:focus {
    outline: none;
    background-color: ${themes.light.neutral1};
    border: 1px solid ${themes.light.neutral4};
    box-shadow: 0px 0px 0px 4px rgba(190, 190, 204, 0.2);
  }
`

const Wrap = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`

const Hold = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  @media (max-width: 640px) {
    margin: 0px 32px;
  }
`

const Head = styled(Title32)`
  color: ${themes.light.neutral9};
  text-align: center;
  font-weight: ${fontWeight.bold};
  margin-bottom: 40px;
`

const Password = (props) => {
  const [password, setPassword] = useState()

  const handleChange = (e) => {
    setPassword(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    handleLogin(password)
    navigate('/work')
  }

  if (isLoggedIn()) {
    navigate(`/work`)
  }

  return (
    <Layout>
      <Wrap>
        <Hold>
          <Slide delay="0.1">
            <Head>
              Please enter the secret password.{' '}
              <span role="img" aria-label="shh emoji">
                🤫
              </span>
            </Head>
          </Slide>
          <Slide delay="0.16">
            <form className="pwHold" onSubmit={handleSubmit}>
              <Input
                name="password"
                type="password"
                placeholder="Let me in."
                onChange={handleChange}
              />
              <BtnPrimary
                image={require('../images/arrowRight.svg').default}
                text="Enter"
                type="submit"
              />
            </form>
          </Slide>
        </Hold>
      </Wrap>
    </Layout>
  )
}

export default Password
